import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import CustomTooltip from "../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";
import {BorderColor} from "@mui/icons-material";
import {filter, find} from "lodash";
import SettingsService, {forms} from "../../services/settings.service";
import MenuItem from "@mui/material/MenuItem";
import {GroupHeader, GroupItems} from "../settings/CheckinFieldsToShow";
import {GlobalContext} from "../../state/global";
import ColumnsService from "../../services/columns.service";
import CustomFieldMultipleUpdate from "../../components/CustomFieldMultipleUpdate";
import axios from "axios";
import UserService from "../../services/user.service";
import CustomBackdrop from "../../components/CustomBackdrop";

const MultipleUserUpateDialog = ({selectedForm, rowSelectionModel, openConfirmDialog, handleToggleConfirmDialog, refetchUsers}) => {
  const [globalState, dispatch] = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)

  const [state, setState] = useState({
    selectedForm: selectedForm || find(globalState.dataStructures, ['main', true]),
    chosenField: null,
    value: null
  })

  const optionsFields = useMemo(() => {
    if (!globalState.dataStructures)
      return []

    const mainForm = !!state.selectedForm ?
      find(globalState.dataStructures, ['id', state.selectedForm?.id])
      : find(globalState.dataStructures, ['main', true])

    let res = filter(
      ColumnsService.getFieldsFromForm(mainForm),
      function (o) {
        return o.type !== SettingsService.fieldTypes.CUSTOM.type
          && o.type !== SettingsService.fieldTypes.ACCESS_TYPE.type
          && o.type !== SettingsService.fieldTypes.URL.type
      }
    )
    res = filter(res, function (o) {
      return !find(SettingsService.commonFields, ['id', o.id])
    })

    //console.log("optionsFields > res:",res)
    return res
  }, [state.selectedForm, globalState.dataStructures]);

  function handleChangeSelectedForm(event) {
    setState({...state, selectedForm: event.target.value, chosenField: null, value: null})
  }

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleToggleDialog = () => {
    setOpenEditDialog(!openEditDialog)
  }


  function handleUpdateMultipleUsers() {
    const value = state?.value?.value || state.value
    setLoading(true)
    axios.put(UserService.userDataUrl(), {
      users: rowSelectionModel,
      fields: {
        [state.chosenField.id]: value
      }
    }).then((res) => {
      handleToggleConfirmDialog()
      handleToggleDialog()
      refetchUsers()
    })
      .catch((err) => {
        //console.log("handleArchiveMultipleUsers > err:", err)
        handleToggleConfirmDialog({
          ...openConfirmDialog,
          responseMessage: "Non è stato possibile modificare gli utenti selezionati"
        })
      })
      .finally(() => setLoading(false))
  }

  /*useEffect(() => {
      console.log('state:', state)
  }, [state])*/

  return (
    <Box //borderRadius={'2rem'}
      //sx={{background: customTheme.palette.accent.main, color: 'white'}}
    >
      <CustomBackdrop open={loading}/>
      <CustomTooltip title={'Modifica multipla'} children={
        <IconButton size={'small'}
                    onClick={() => handleToggleDialog(handleUpdateMultipleUsers)}
        >
          <BorderColor fontSize={'small'}/>
        </IconButton>
      }/>
      <Dialog open={openEditDialog} onClose={handleToggleDialog} maxWidth={'md'} fullWidth>
        <DialogTitle>
          {`Modifica multipla di ${rowSelectionModel.length} partecipanti`}
        </DialogTitle>
        <DialogContent>
          <Stack mt={2} direction={"row"} spacing={2}>
            <TextField variant={'outlined'} size={'small'}
                       select
                       sx={{width: '60%'}}
                       id={'form'}
                       name={'form'}
                       label={'Form'}
                       defaultValue={find(globalState.dataStructures, ['main', true])}
                       value={state.selectedForm}
                       onChange={handleChangeSelectedForm}
            >
              {
                filter(Object.values(globalState.dataStructures || []), function (o) {
                  return o.id !== forms.CHECKIN.id
                }).map((option) => (
                  <MenuItem key={option.label} value={option} children={
                    <Stack direction={'row'} spacing={1}>
                      <div>{option.label}</div>
                    </Stack>
                  }/>
                ))}
            </TextField>
            <Stack width={'100%'} direction={'row'} alignItems={'flex-start'}>
              <Autocomplete
                fullWidth
                size={'small'}
                disableCloseOnSelect
                groupBy={(option) => option.section}
                onChange={(event, newValue) => {
                  if (newValue)
                    setState({
                      ...state,
                      chosenField: newValue
                    })
                }}
                renderInput={(params) =>
                  <TextField {...params}
                             label="Scegli il campo da modificare"
                  />}
                options={optionsFields}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
              />
            </Stack>
          </Stack>
          {state.chosenField &&
            <CustomFieldMultipleUpdate field={state.chosenField}
                                       value={state.value}
                                       setValue={(val) => {
                                         setState({
                                           ...state,
                                           value: val
                                         })
                                       }}
                                       disabled={state.chosenField?.precompiled}/>}
        </DialogContent>
        <DialogActions>
          <Button variant={'dialog-button'} onClick={handleToggleDialog}>{'Annulla'}</Button>
          <Button variant={'submit-accent'}
                  onClick={() => handleToggleConfirmDialog(handleUpdateMultipleUsers)}
          >
            {'SALVA'}
          </Button>
        </DialogActions>
        {/*!!openEditDialog?.responseMessage &&
                    <Alert elevation={6} variant={"filled"}
                           severity={'error'}
                           sx={{width: '100%'}}>
                        {openEditDialog?.responseMessage || ''}
                    </Alert>*/}
      </Dialog>
    </Box>
  )
}

export default MultipleUserUpateDialog