import React, {useContext, useState} from "react";
import {alpha, Button, Card, CardActions, CardContent, CardHeader, Divider, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AddCircle, Edit} from "@mui/icons-material";
import {customTheme} from "../theme/customTheme";
import CustomTooltip from "./CustomTooltip";
import {GlobalContext} from "../state/global";
import HandleFormDialog, {SettingsDialogTypes} from "../pages/settings/HandleFormDialog";

const CustomFormCard = ({id, label, description}) => {
    const [globalState, ] = useContext(GlobalContext)
    //console.log("globalState:",globalState)
    //console.log("globalState.dataStructures[id]:",globalState?.dataStructures[id])

    const [dialogState, setDialogState] = useState({
        open: false,
        data: null,
        type: null
    })

    const handleOpenDialog = (e) => {
        switch (e.currentTarget.id) {
            case 'edit-form':
                setDialogState({
                    open: true,
                    type: SettingsDialogTypes.DATA_STRUCTURES_INFO,
                    data: globalState.dataStructures[id]
                })
                break
            case 'add-field':
                //console.log("add-field:",id)
                setDialogState({
                    open: true,
                    type: SettingsDialogTypes.DATA_STRUCTURES_FIELDS,
                    data: globalState.dataStructures[id]
                })
        }

    }

    return (
        <Card sx={{ maxWidth: 345, minWidth: 260, background: alpha(customTheme.palette.primary.main, 0.1) }}>
            <CardHeader
                /*avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        R
                    </Avatar>
                }*/
                action={
                    <IconButton id={'edit-form'} color={'accent'} onClick={handleOpenDialog}>
                        <Edit/>
                    </IconButton>
                }
                title={label}
                subheader={`id: ${id}`}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <Divider/>
            <CardActions disableSpacing>
                <CustomTooltip title={'Aggiungi campi personalizzati di segreteria'} children={
                    <Button aria-label="add"
                            id={'add-field'}
                            //variant={'outlined'}
                            color={'accent'}
                            size={'small'}
                            startIcon={<AddCircle />}
                            onClick={handleOpenDialog}
                    >
                        Aggiungi campi
                    </Button>
                }/>
            </CardActions>
            <HandleFormDialog dialogState={dialogState} setDialogState={setDialogState}/>
        </Card>
    )
}

export default CustomFormCard