import {
  AccessTime,
  AlternateEmail,
  CheckBox,
  CheckBoxOutlineBlank,
  DateRange, FiberManualRecord,
  HorizontalRule,
  Info,
  Link,
  MenuOpen,
  Notes, Numbers,
  QrCodeScanner,
  RadioButtonChecked,
  TextFields,
  Today,
  Tune,
  UploadFile
} from "@mui/icons-material";
import React from "react";
import {camelCase, forEach, replace} from "lodash";

export const getLocaleDateTime = ({ts_seconds}) => {
  return new Date(ts_seconds * 1000).toLocaleString('it-IT')
}

export const icon = <CheckBoxOutlineBlank fontSize="small" />;
export const checkedIcon = <CheckBox fontSize="small" />;

export function getId(fieldName) {
  return fieldName ? camelCase(fieldName.trim()
    .replaceAll(/[^a-zA-Z0-9\s]+/g, "")
    .replaceAll(/\s+/g, "-")) : ""
}

export function parseMultiLineTextBox(value) {
  return value ?
    replace(replace(value.trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
    : "";
}

export function testTime(value) {
  return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
}

export const getColorFromCategoria = (braccialetto) => {
  switch (braccialetto) {
    case 'agente':
    case 'direttore':
    case 'presidente':
      return '#0095d9'
    case 'dipendente':
      return '#f59058'
    default:
      return null
  }
}