import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import {styled, useTheme} from '@mui/material/styles';
import {VariableSizeList} from 'react-window';
import {Button, Chip, ListItem, ListItemIcon} from "@mui/material";
import {CheckCircle, InfoOutlined} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  const val = dataSet[1]

  return (
    <ListItem
      {...dataSet[0]}
      style={inlineStyle}
      key={val.id}
      secondaryAction={
        (val.checkin === false || !val.checkin) ?
          <Button variant={"outlined"}
                  size={'small'}
                  endIcon={<CheckCircle sx={{ color: 'darkseagreen', fontSize: '2.5rem' }} />}
                  onClick={(e) => props.checkinUser(e, val)}
          >
            Check-in
          </Button>
          : val.checkedInAt ?
            <Chip label={`Check-in ${val.checkedInAt ? new Date((val.checkedInAt?._seconds * 1000)).toLocaleTimeString('it-IT') : ''}`}/>
            : val.email
      }
    >
      <ListItemIcon>
        <IconButton onClick={(e) => props.showInfo(e, val)}>
          <InfoOutlined fontSize={'small'} color={'primary'}/>
        </IconButton>
      </ListItemIcon>
      <ListItemText
        primary={val.nome +  ' ' + val.cognome}
        secondary={/*val[props.colorField] ?
                    <Chip size={'small'}
                          label={val.email}
                          sx={{
                              marginTop: 0.5,
                              fontWeight: 'bold',
                              // TODO CHECK /
                              border: `2px solid ${darken(val ? (val[props.colorField] || (getColorFromCategoria(val[props.colorField]))) : 'white', 0.2)}`,
                              background: val ? (val[props.colorField] || getColorFromCategoria(val?.categoria) || 'inherit') : 'inherit',
                              color:  val ? customTheme.palette.getContrastText(val[props.colorField] || getColorFromCategoria(val?.categoria)) : 'white'
                          }}
                    /> : */val.codiceAgenzia ? `Codice Agenzia: ${val.codiceAgenzia}`: ""}
      />
    </ListItem>
  )
  /*return (
      <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
          {`#${dataSet[2] + 1} - ${dataSet[1]?.nome} ${dataSet[1]?.cognome}`}
      </Typography>
  );*/
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize*2;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount*2);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 4 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {(_props) => renderRow({
            ..._props,
            checkinUser: props.checkinUser,
            showInfo: props.showInfo,
            colorField: props.colorField
          })}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const filterOptions = (options, { inputValue }) => {
  const inputParts = inputValue.toLowerCase().split(' ').filter(Boolean); // Filtra via spazi extra e suddividi
  return options.filter((option) => {
    // Unisci il nome e il cognome e verifica se ogni parte dell'input è presente
    const fullName = `${option.nome.trim()} ${option.cognome.trim()}`.toLowerCase();
    return inputParts.every(part => fullName.includes(part));
  });
};

export default function VirtualizedObjsList({options, checkinUser, showInfo, colorField}) {
  return (
    <Autocomplete
      id="virtualize-demo"
      sx={{ px: 2 }}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      ListboxProps={{checkinUser: checkinUser, showInfo: showInfo, colorField: colorField}}
      options={options}
      getOptionLabel={(option) => (`${option.nome} ${option.cognome}`)}
      filterOptions={filterOptions}
      //groupBy={(option) => option[0].toUpperCase()}
      renderInput={(params) => <TextField {...params} label="Cerca partecipanti per cognome e nome" />}
      renderOption={(props, option, state) => [props, option, state.index]}
      //renderGroup={(params) => params}
    />
  );
}