import {cloneDeep, findIndex} from "lodash";
import {getId} from "../../services/utils";
import {Checkbox, FormControl, FormControlLabel, FormGroup, Stack, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Risposte from "./Risposte";
import CustomTooltip from "../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";
import {DragHandle} from "@mui/icons-material";
import React from "react";
import FieldColorOptions from "./FieldColorOptions";
import SettingsService from "../../services/settings.service";

function FieldForm({provided, section, field, index, formData, setFormData}) {

  const handleChangeField = (event) => {
    const _sections = cloneDeep(formData.sections)
    const _sectionIndex = findIndex(_sections, ['id', section])
    const _section = _sections[_sectionIndex]
    //console.log("field:", field)

    if (_section && _section.fields[index]) {
      _section.fields[index].type = event.target.value
      //console.log("_fieldIndex:", index)

      /*console.log("res:", {
          ...formData,
          sections: _sections
      })*/
      setFormData({
        ...formData,
        sections: _sections
      })
    }
  }

  /** Le opts essere come eg: {label: "Sì", value: "si"} */
  const handleSetOptions = (opts, color) => {
    const _sections = cloneDeep(formData.sections)
    const _sectionIndex = findIndex(_sections, ['id', section])
    const _section = _sections[_sectionIndex]

    if (_section.fields[index]) {
      if(color) {
        _section.fields[index].values = opts.map((opt) => ({
          label: opt?.label || opt?.value || opt,
          value: getId(opt.value) || getId(opt),
          hex: opt.hex || getId(opt.value) || getId(opt),
        }))
      } else {
        _section.fields[index].values = opts.map((opt) => ({
          label: opt?.label || opt?.value || opt,
          value: getId(opt.value) || getId(opt),
        }))
      }
      //console.log("_fieldIndex:", index)

      /*console.log("res:", {
          ...formData,
          sections: _sections
      })*/
      setFormData({
        ...formData,
        sections: _sections
      })
    }
  }

  const handleFieldLabel = (event, field) => {
    const _sections = cloneDeep(formData.sections)
    const _sectionIndex = findIndex(_sections, ['id', section])
    const _section = _sections[_sectionIndex]
    //console.log("handleFieldLabel > field:", field)
    //console.log("handleFieldLabel > _section:", _section)

    if (_section.fields[index]) {
      _section.fields[index].label = event.target.value
      _section.fields[index].id = _section.fields[index].id || getId(_section.fields[index].label)
      //console.log("_fieldIndex:", index)

      /*console.log("res:", {
          ...formData,
          sections: _sections
      })*/
      setFormData({
        ...formData,
        sections: _sections
      })
    }
  }

  function handleFieldPrecompiled(event, checked) {
    const _sections = cloneDeep(formData.sections)
    const _sectionIndex = findIndex(_sections, ['id', section])
    const _section = _sections[_sectionIndex]
    //console.log("handleFieldLabel > field:", field)
    //console.log("handleFieldLabel > _section:", _section)

    if (_section.fields[index]) {
      _section.fields[index].precompiled = checked

      /*console.log("res:", {
          ...formData,
          sections: _sections
      })*/
      setFormData({
        ...formData,
        sections: _sections
      })
    }
  }

  return (
    <Stack direction={'row'} alignItems={'baseline'} padding={1}>
      <Stack width={'100%'} spacing={1}>
        <Stack width={'100%'} direction={'row'} alignItems={'baseline'} spacing={1}>
          <TextField variant={'standard'}
                     fullWidth
                     label={'Nome del campo'}
                     placeholder={`Nome del campo`}
                     value={field.label}
                     onChange={(event) => handleFieldLabel(event, field)}
                     helperText={`id: ${field.id || getId(field.label)}`}
          />
          <TextField variant={'outlined'} size={'small'}
                     select
                     fullWidth
            //id={'field.type'}
            //name={'field.type'}
                     label={'Tipo del campo'}
                     value={field.type}
                     defaultValue={SettingsService.fieldTypes.TEXT}
                     onChange={handleChangeField}
          >
            {
              Object.values(SettingsService.fieldTypes).map((option) => (
                <MenuItem key={option.type} value={option.type} children={
                  <Stack direction={'row'} spacing={1}>
                    <div>{option.icon}</div>
                    <div>{option.label}</div>
                  </Stack>
                }/>
              ))}
          </TextField>
        </Stack>
        {(field.type === SettingsService.fieldTypes.SELECT.type
            || field.type === SettingsService.fieldTypes.RADIO_GROUP.type)
          && <Risposte risp={field.values.map((value) => (value.label))} handleSetRisposte={handleSetOptions}
                       title={'Aggiungi le opzioni possibili'} optionLabel={'Opzione'}
                       startIndex={0}
          />}
        {(field.type === SettingsService.fieldTypes.COLOR.type)
          && <FieldColorOptions risp={field.values} handleSetRisposte={handleSetOptions}
                                title={'Aggiungi le opzioni possibili'} optionLabel={'Colori Possibili'}
                                startIndex={0}
          />}
        <FormGroup>
          <FormControlLabel
            label={"Campo precompilato"}
            slotProps={{
              typography: {
                variant: 'subtitle2'
              }
            }}
            control={<Checkbox
              size={'small'}
              checked={field.precompiled}
              onChange={(event, checked) => {
                handleFieldPrecompiled(event, checked)
              }}
            />}
          />
        </FormGroup>
      </Stack>
      <Stack direction={'row-reverse'} sx={{marginLeft: 'auto'}}>
        {/*<SectionMenu/>*/}
        <CustomTooltip title={"Riordina"}>
          <IconButton
            aria-label="settings" {...provided.dragHandleProps}>
            <DragHandle/>
          </IconButton>
        </CustomTooltip>
      </Stack>
    </Stack>
  );
}

export default FieldForm
