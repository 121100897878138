import React, {useContext, useState} from "react";
import {Link, useLocation, useParams} from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Fab,
  Grid,
  Skeleton,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import UserService from "../../services/user.service";
import {AddCircle, Archive, ArrowBackIosNew, ExpandMore, LocalOffer, Save} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import useAxios from "axios-hooks";
import CustomField from "../../components/CustomField";
import {useFormik} from "formik";
import _, {filter, find, isEmpty, replace} from 'lodash'
import CustomizedTimeline from "../../components/CustomTimeline";
import {APP_BAR_HEIGHT, customTheme} from "../../theme/customTheme";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomPageHeader from "../../components/CustomPageHeader";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../../components/CustomTooltip";
import {GlobalContext} from "../../state/global";
import UserDetailsMenu from "./UserDetailsMenu";
import ResendEmailMenu from "./ResendEmailMenu";
import axios from "axios";
import FormCompletedCustomChip from "./FormCompletedCustomChip";
import SettingsService from "../../services/settings.service";

const UserDetails = ({readOnly}) => {
  const [globalState, dispatch] = useContext(GlobalContext)

  const {id} = useParams() //dc402b1dcaea136215f9b588a25c76ec
  const location = useLocation()

  const activeFormId = location?.state?.activeFormId || find(globalState.dataStructures, ['main', true])?.id || 'iscrizioni'

  const [message, setMessage] = useState({show: false, text: "", severity: "success"})

  const [{data: userData, loading: loadingUserData, error: errorUserData}, fetchUser] = useAxios(
    UserService.userDataUrl(id), {method: "GET", useCache: false}
  )

  const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
    {manual: true}
  )

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage({...message, show: false});
  };

  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: true,
    //validationSchema: // TODO
  });

  const isValid = () => {
    return formik.dirty && formik.isValid && !readOnly
  }

  const handleSubmitForm = () => {
    //console.log('formik:',formik)
    if (isValid()) {
      //console.log("formik:",formik)
      const dto = {}
      Object.keys(formik.touched).forEach((touchedField) => {
        if (formik.values[touchedField] !== formik.initialValues[touchedField]) {
          if (touchedField === 'noteSegreteria') {
            /** TODO: migliorare per tutte le aree di testo !!! */
            dto[touchedField] = replace(replace(formik.values[touchedField].trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
          } else dto[touchedField] = formik.values[touchedField]
        }
      })

      //console.log("dto:", dto)
      if (!_.isEmpty(dto)) {
        updateUser({
          data: {
            data: dto,
            form_id: activeFormId
          },
          url: UserService.userDataUrl(id),
          method: "PUT"
        })
          .then(() => {
            setMessage({
              ...message,
              show: true,
              text: "Aggiornamento effettuato con successo",
              severity: "success"
            })
            fetchUser()
          })
          .catch((err) => {
            setMessage({
              ...message,
              show: true,
              text: "Aggiornamento non andato a buon fine",
              severity: "error"
            })
          })
      }
    }
  }

  return (
    <main>
      <CustomBackdrop open={loadingUserData || loadingUpdate}/>
      <Box display={'flex'} alignItems={'center'}
           position={'sticky'}
           top={APP_BAR_HEIGHT * 2}
           sx={{zIndex: 1, backgroundColor: 'white'}}
           pb={1}
      >
        <CustomTooltip
          title={'Torna all\'elenco'}
          children={<IconButton color={'accent'} LinkComponent={Link} to={"/iscrizioni"}>
            <ArrowBackIosNew/>
          </IconButton>}/>
        {userData ?
          <CustomPageHeader title={`${userData?.nome || ''} ${userData?.cognome || ''}`}
                            subtitle={'Dati utente'}
                            refresh={fetchUser} gutterBottom={false}/>
          : loadingUserData ?
            <Skeleton variant="text" sx={{fontSize: '1.5rem'}} animation={"wave"} width={'20%'}/>
            : null}
        <FormCompletedCustomChip completionField={userData ? userData[activeFormId] : null} long/>
        {userData?.archived &&
          <Chip variant={'accent-outlined'}
                label={'UTENTE ARCHIVIATO'}
                size={'small'}
                sx={{ml: 1}}
                icon={<Archive fontSize={'small'} color={"accent"}/>}/>}
        {!readOnly &&
          <Box marginLeft={'auto'}>
            <ResendEmailMenu userId={id} archived={userData?.archived} setMessage={setMessage}/>
            <Button
              color={'accent'}
              variant={'outlined'}
              onClick={handleSubmitForm}
              disabled={!isValid()}
              startIcon={<Save/>}
              sx={{marginLeft: 1}}
            >
              SALVA
            </Button>
            <UserDetailsMenu userId={id} updateUser={updateUser} archive={!userData?.archived}
                             onSuccess={() => {
                               setMessage({
                                 show: true,
                                 text: "Aggiornamento effettuato con successo",
                                 severity: "success"
                               })
                               fetchUser()
                             }}
                             onError={() => setMessage({
                               show: true,
                               text: "Aggiornamento non andato a buon fine",
                               severity: "error"
                             })}/>
          </Box>}
      </Box>
      <Container>
        <form onSubmit={handleSubmitForm}>
          {userData?.mailchimp &&
            <Accordion defaultExpanded={false}
                       sx={{background: alpha(customTheme.palette.primary.main, 0.2), borderRadius: '4px'}}>
              <AccordionSummary expandIcon={<ExpandMore/>} sx={{alignItems: 'center'}}>
                <Typography variant={"h6"} textAlign={'center'}>
                  Attività su Mailchimp
                </Typography>
                <Chip label={`${filter(userData.mailchimp?.activity, function (o) {
                  return Object.keys(o).length > 0
                })?.length || 0} azioni`}
                      color={'primary'}
                      variant={'outlined'}
                      size={'small'}
                      sx={{margin: 0.5, marginLeft: 1}}
                />
                {userData?.mailchimpTags &&
                  <Chip label={`${userData?.mailchimpTags?.tags?.length || 0} tag`}
                        color={'primary'}
                        variant={'outlined'}
                        size={'small'}
                        sx={{margin: 0.5, marginLeft: 1}}
                  />}
                <Stack direction={"row"} flexWrap={'wrap'}>
                  {userData?.mailchimpTags &&
                    userData?.mailchimpTags?.tags?.map((tag, i) => (
                      <CustomTooltip title={'Rimuovi tag da Mailchimp'}
                                     key={i}
                                     children={<Chip label={`${tag.name}`}
                                                     color={'primary'}
                                                     size={'small'}
                                                     sx={{margin: 0.5}}
                                                     icon={<LocalOffer/>}
                                                     onDelete={globalState.authState.isAdmin ?
                                                       () => {
                                                         console.log("remove tag", tag)
                                                         axios.post(
                                                           `${UserService.userDataUrl(id)}/remove-tag/${tag.name}`
                                                         ).then(r => {
                                                           setMessage({
                                                             ...message,
                                                             show: true,
                                                             text: "Aggiornamento effettuato con successo",
                                                             severity: "success"
                                                           })
                                                           fetchUser()
                                                         })
                                                       } : null}
                                     />}
                      />
                    ))
                  }
                  {
                    globalState.authState.isAdmin &&
                    <Chip label={`Aggiungi Tag`}
                          color={'primary'}
                          variant={'outlined'}
                          size={'small'}
                          disabled // TODO: fare poi il menù con tag possibili
                          sx={{margin: 0.5}}
                      //icon={<AddCircle/>}
                          onClick={globalState.authState.isAdmin ?
                            (event) => {
                              event.stopPropagation()
                              axios.post(
                                `${UserService.userDataUrl(id)}/add-tag/:tag`
                              ).then(r => {
                                setMessage({
                                  ...message,
                                  show: true,
                                  text: "Aggiornamento effettuato con successo",
                                  severity: "success"
                                })
                                fetchUser()
                              })
                            } : null}
                          deleteIcon={<AddCircle/>}
                          onDelete={globalState.authState.isAdmin ?
                            () => {
                              axios.post(
                                `${UserService.userDataUrl(id)}/add-tag/:tag`
                              ).then(r => {
                                setMessage({
                                  ...message,
                                  show: true,
                                  text: "Aggiornamento effettuato con successo",
                                  severity: "success"
                                })
                                fetchUser()
                              })
                            } : null}
                    />
                  }
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <CustomizedTimeline mailchimpActivity={userData.mailchimp?.activity}/>
              </AccordionDetails>
            </Accordion>
          }
          {
            globalState.dataStructures ?
              globalState.dataStructures[activeFormId]?.sections?.map((section, i) => {
                if (userData && formik.values) {
                  if (!isEmpty(section.fields) && (!(section.condition
                    && formik.values[section.condition.id] !== section.condition.value))) {
                    return <Card key={i} variant={'outlined'}
                                 sx={{
                                   padding: '1rem',
                                   margin: '0.5rem 0',
                                   boxShadow: '1px 1px 6px 0px rgb(140 144 146 / 15%)'
                                 }}>
                      <Typography color={customTheme.palette.primary.main} variant={"h6"}
                                  sx={{textAlign: 'left'}}>{section.label}</Typography>
                      <Grid container spacing={3}>
                        {
                          filter(section.fields, function (o) {
                            const field = find(SettingsService.fieldTypes, ['type', o.type])
                            return !field?.onlyUserForm
                          }).map((field, i) => {
                            if (!!field?.conditions) {
                              const res = field.conditions.type === 'or' ?
                                _.some(field.conditions.predicates, function (o) {
                                  return formik.values[o.id] === o.value
                                }) : field.conditions.type === 'and' ?
                                  _.every(field.conditions.predicates, function (o) {
                                    return formik.values[o.id] === o.value
                                  }) : null
                              if (res)
                                return <CustomField key={i} formik={formik}
                                                    field={field}
                                                    userId={id}
                                                    disabled={!section.editable
                                                      || userData.archived}
                                                    refetch={fetchUser}
                                />
                            } else if (!(field.condition
                              && formik.values[field.condition.id] !== field.condition.value))
                              return <CustomField key={i} formik={formik} field={field}
                                                  userId={id}
                                                  disabled={!section.editable
                                                    || userData.archived}
                                                  refetch={fetchUser}
                              />
                          })
                        }
                      </Grid>
                    </Card>
                  }
                } else {
                  return <Skeleton key={i}
                                   variant="rounded"
                                   width={'100%'} height={'110px'}
                                   animation={"wave"}
                                   sx={{marginBottom: 1}}/>
                }
              })
              : userData
              && formik.values
              && globalState.dataStructures ?
                <Card sx={{padding: '1rem', margin: '0.5rem 0'}}
                      variant={'outlined'}>
                  <Typography color={customTheme.palette.primary.main} variant={"h6"}
                              sx={{textAlign: 'left'}}>{globalState.dataStructures[activeFormId].label}</Typography>
                  <Grid container spacing={3}>
                    {
                      (globalState.dataStructures[activeFormId]?.fields || []).map((field, i) => {
                        if (!!field.conditions) {
                          const res = _.some(field.conditions, function (o) {
                            return formik.values[o.id] === o.value
                          })
                          if (res)
                            return <CustomField key={i} formik={formik} field={field}
                                                userId={id}
                                                disabled={!globalState.dataStructures[activeFormId].editable}
                                                refetch={fetchUser}
                            />
                        } else if (!(field.condition
                          && formik.values[field.condition.id] !== field.condition.value))
                          return <CustomField key={i} formik={formik} field={field} userId={id}
                                              disabled={!globalState.dataStructures[activeFormId].editable}
                                              refetch={fetchUser}
                          />
                      })
                    }
                  </Grid>
                </Card>
                : <Skeleton variant="rounded"
                            width={'100%'} height={'110px'}
                            animation={"wave"}
                            sx={{marginBottom: 1}}/>
          }
          {!readOnly &&
            <Fab onClick={handleSubmitForm}
                 disabled={!isValid()}
                 color={'primary'}
                 size={'medium'}
                 variant={'action'}
            >
              <Save/>
            </Fab>}
        </form>
      </Container>
      <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert} severity={message.severity}
               sx={{width: '100%'}}>
          {message.text}
        </Alert>
      </Snackbar>
    </main>
  )
}

export default UserDetails
