import CustomPieChart from "./CustomPieChart";
import React, {useContext, useMemo, useState} from "react";
import {Autocomplete, Box, Divider, Paper, TextField} from "@mui/material";
import {filter, find} from 'lodash'
import CustomTimelineChart from "./CustomTimelineChart";
import ColumnsService from "../../services/columns.service";
import {GlobalContext} from "../../state/global";
import SettingsService from "../../services/settings.service";

function CustomDashboardGraph({utenti, activeForm}) {
  const [globalState,] = useContext(GlobalContext)
  const {dataStructures} = globalState
  const form = dataStructures[activeForm.id]
  const [chosenField, setChosenField] = useState(null)

  const fields = useMemo(() => {
    const _fields = filter(
      ColumnsService
        .getFieldsFromForm(dataStructures[activeForm.id]),
      function (o) {
        return o.type === SettingsService.fieldTypes.SELECT.type
          || o.type === SettingsService.fieldTypes.CHECK_BOX.type
          || o.type === SettingsService.fieldTypes.RADIO_GROUP.type}
    )

    if(_fields && _fields.length > 0) {
      setChosenField(_fields[0])
    } else setChosenField(null)

    return _fields
  }, [activeForm])

  return <Box height={'100%'}>
    <Divider variant={'fullWidth'}/>
    <Box height={'100%'} display={'flex'} flexWrap={'wrap'} justifyContent={'start'}>
      <Box flex={0.5} mr={1} mt={1} component={Paper} height={'60%'} justifyContent={'space-between'}>
        <Autocomplete
          sx={{width: '50%', padding: 1}}
          size={'small'}
          value={chosenField}
          onChange={(event, newValue) => {
            setChosenField(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Campi" />}
          options={fields}
        />
        <CustomPieChart chosenField={chosenField} utenti={utenti}/>
      </Box>
      <Box flex={0.5} mt={1} component={Paper} height={'60%'} justifyContent={'space-evenly'}>
        <CustomTimelineChart utenti={utenti}
                             timelineField={form.timelineField || form?.id}
                             minutes={form?.precisionMinute}/>
      </Box>
    </Box>
  </Box>;
}

export default CustomDashboardGraph