import React from "react";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "./auth-header";
import {cloneDeep, find, orderBy} from "lodash";
import {
  AccessTime,
  AlternateEmail,
  CheckBox,
  DateRange,
  FiberManualRecord,
  HorizontalRule,
  Info,
  Link,
  MenuOpen,
  Notes,
  Numbers,
  QrCodeScanner,
  RadioButtonChecked,
  TextFields,
  Today,
  Tune,
  UploadFile
} from "@mui/icons-material";

export const forms = {
  CHECKIN: {
    id: "checkin",
    label: "Check-in",
    position: 100,
    timelineField: 'checkedInAt',
    precisionMinute: true,
    description: 'Struttura che consente di monitorare l\'accesso all\'evento',
    sections: [
      {
        "editable": false,
        "label": "Check-In",
        "mainSection": true,
        fields: [
          {
            "defaultVisible": true,
            "id": "checkedInAt",
            "label": "Orario di accesso",
            "type": "datetime"
          },
          {
            "defaultVisible": true,
            "id": "checkin",
            "label": "Accesso",
            "type": "checkbox"
          },
        ]
      }
    ]
  },
  ARCHIVED: {
    id: "archived",
    label: "",
    position: 101,
    timelineField: 'updatedAt',
    precisionMinute: false,
    description: 'Utenti archiviati'
  },
}

const settingsUrl = () => {
  return `${API_URL}config/settings`
}

const getSettings = () => {
  return axios.get(settingsUrl(), {headers: authHeader()})
};

const saveSettings = (settings) => {
  return axios.post(settingsUrl(), {...settings}, {headers: authHeader()})
};

const getForms = (settings, dataStructures) => {
  const res = cloneDeep(dataStructures)
  const length = Object.values(res).length

  if (!settings?.checkin) {
    delete res[forms.CHECKIN.id]
  } else {
    res[forms.CHECKIN.id] = {
      ...forms.CHECKIN,
      sections: [...find(dataStructures, ['main', true]).sections, ...forms.CHECKIN.sections],
      position: length
    }
  }
  return orderBy(Object.values(res), 'position')
};

const fieldTypes = {
  TEXT: {icon: <TextFields color={'disabled'}/>, type: "text", label: "Testo", admin: false},
  NUMBER: {icon: <Numbers color={'disabled'}/>, type: "number", label: "Numero", admin: false},
  EMAIL: {icon: <AlternateEmail color={'disabled'}/>, type: "email", label: "E-mail", admin: false},
  SELECT: {icon: <MenuOpen color={'disabled'}/>, type: "select", label: "Menù a scelta", admin: true},
  DATE: {icon: <DateRange color={'disabled'}/>, type: "date", label: "Data", admin: false},
  DATE_TIME: {icon: <Today color={'disabled'}/>, type: "datetime", label: "Data e Ora", admin: false},
  TIME: {icon: <AccessTime color={'disabled'}/>, type: "time", label: "Ora", admin: false},
  RADIO_GROUP: {icon: <RadioButtonChecked color={'disabled'}/>, type: "radiogroup", label: "Radio group", admin: true},
  CHECK_BOX: {icon: <CheckBox color={'disabled'}/>, type: "checkbox", label: "Checkbox", md: 12, admin: true},
  TEXT_BOX: {icon: <Notes color={'disabled'}/>, type: "textbox", label: "Area di testo", admin: false},
  URL: {icon: <Link color={'disabled'}/>, type: "url", label: "URL", admin: false},
  COLOR: {icon: <FiberManualRecord color={'disabled'}/>, type: "color", label: "Color", admin: true},
  CUSTOM: {icon: <Tune color={'disabled'}/>, type: "custom", label: "Custom", admin: true},
  ACCESS_TYPE: {icon: <QrCodeScanner color={'disabled'}/>, type: "accessType", label: "Tipo di accesso", admin: true},
  NOTE: {icon: <Info color={'disabled'}/>, type: "note", label: "Note", admin: true, onlyUserForm: true},
  DIVIDER: {
    icon: <HorizontalRule color={'disabled'}/>,
    type: "divider",
    label: "Divisore",
    admin: true,
    noDataGrid: true
  },
  FILE_UPLOAD: {
    icon: <UploadFile color={'disabled'}/>,
    type: "fileupload",
    label: "Caricamento File",
    admin: false,
    noDataGrid: false
  },
}

const participationFields = [
  /*{
    "id": "id",
    "label": "ID",
    "type": "text",
    "precompiled": true,
    "defaultVisible": true
  },*/
  {
    "id": "partecipazione",
    "label": "Partecipazione",
    "type": fieldTypes.RADIO_GROUP.type,
    "precompiled": false,
    "defaultVisible": true,
    "values": [
      { value: "si", label: "Si" },
      { value: "online", label: "Online" },
      { value: "no", label: "No" },
    ]
  },
  {
    "id": "accessType",
    "label": "Modalità di accredito",
    "type": fieldTypes.ACCESS_TYPE.type,
    "defaultVisible": true
  }
]

/** TODO: sistemare i commonFields */
const commonFields = [
  {
    "id": "id",
    "label": "ID",
    "type": "text",
    "precompiled": true,
    "defaultVisible": true
  },
  {
    "id": "email",
    "label": "E-mail",
    "type": "email",
    "precompiled": true,
    "defaultVisible": true
  },
  {
    "id": "nome",
    "label": "Nome",
    "type": "text",
    "defaultVisible": true
  },
  {
    "id": "cognome",
    "label": "Cognome",
    "type": "text",
    "defaultVisible": true
  }
]

const noteSegreteriaFields = [
  {
    "id": "noteSegreteria",
    "label": "Note Segreteria",
    "type": fieldTypes.TEXT_BOX.type,
    "precompiled": false,
    "defaultVisible": true
  }
]

const SettingsService = {
  participationFields,
  commonFields,
  noteSegreteriaFields,
  fieldTypes,
  settingsUrl,
  getSettings,
  saveSettings,
  getForms
};

export default SettingsService;