import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Box, ListItem, ListItemIcon, Typography} from "@mui/material";
import {QrReader} from 'react-qr-reader';
import {API_URL} from "../config";
import axios from "axios";
import SearchUserDialog from "../components/checkin/SearchUserDialog";
import ConfirmCheckinDialog, {OptionalCheckinUserData} from "../components/checkin/ConfirmCheckinDialog";
import CheckinAppBar from "../components/checkin/CheckinAppBar";
import {Info} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import {APP_BAR_HEIGHT, customTheme} from "../theme/customTheme";
import useAxios from "axios-hooks";
import CustomBackdrop from "../components/CustomBackdrop";

const CheckIn = () => {
  document.body.style.backgroundColor = customTheme.palette.secondary.main;

  const [{data: colorField, loading: loadingColor , error: errorColor}] = useAxios(
    `${API_URL}color-field`, {method: "GET", useCache: false, manual: false}
  )

  const [count, setCount] = useState(0);
  const [data, setData] = useState('');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [alreadyScanned, setAlreadyScenned] = useState(false);
  const [error, setError] = useState(false);
  const constraints = {
    facingMode: "environment"
  };

  useEffect(() => {
    fetchData().then()
  }, [user])

  const getUser = async (id) => {
    const _user = await axios.get(API_URL + "participantsFisici/" + id);
    setUser(_user.data)
  };

  const checkinUser = async (id) => {
    setAlreadyScenned(false);
    setError(false);
    setUser(null)
    try {
      await axios.get(API_URL + "participantsFisici/" + id + "/checkin");
      await fetchData()
      return true;
    } catch (e) {
      if (e.response.status === 409) {
        setAlreadyScenned(e);
        console.log(e);
        return true;
      } else {
        setError(e);
        return false;
      }
    }
  };

  useEffect(() => {
    if (data !== '') {
      checkinUser(data).then(resCheckin => {
        if (resCheckin) {
          getUser(data).then(_ => setCount(count + 1))
          setConfirmOpen(true);
        }
      });
    }
  }, [data])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (res) => {
    if (res && res.hash) {
      checkinUser(res.hash)
        .then(resCheckin => {
          if (resCheckin) {
            getUser(res.hash).then(_ => setCount(count + 1))
            setConfirmOpen(true);
          }
        });
    }
    setOpen(false);
  };

  async function fetchData() {
    setLoading(true)
    const _userList =
      await axios.get(API_URL + "participantsFisici")
        .finally(() => setLoading(false))
    setUserList(_userList.data)
  }

  function hanldleCloseAlert() {
    setAlreadyScenned(false)
    setError(false)
    setCount(0)
  }

  return (
    <main>
      <CustomBackdrop open={loading || loadingColor}/>
      <CheckinAppBar handleDialogOpen={handleClickOpen}/>
      {count > 0 && !error &&
        <Box position={'fixed'} top={'50px'} left={0} right={0} zIndex={1}>
          <Alert severity="success" sx={{m: 2, boxShadow: '0px 1px 18px 0px rgba(48,66,75,0.45)'}}
                 onClose={hanldleCloseAlert}
          >
            <AlertTitle>
              {user?.nome} {user?.cognome}
            </AlertTitle>
            <Typography variant={'subtitle2'}>{user?.email}</Typography>
          </Alert>
        </Box>}
      {alreadyScanned &&
        <Box position={'fixed'} top={'50px'} left={0} right={0} zIndex={1}>
          <Alert severity={"error"} sx={{m: 2, boxShadow: '0px 1px 18px 0px rgba(48,66,75,0.45)'}}
                 onClose={hanldleCloseAlert}
          >
            <AlertTitle>QR Code già scansionato {user?.checkinScanCount ? user?.checkinScanCount+' volte' : ''}.</AlertTitle>
            <Typography variant={'subtitle2'}>
              {/*alreadyScanned.response.data.error*/}
              {`L'utente ha già fatto check-in alle ${new Date((user?.checkedInAt?._seconds * 1000))
                .toLocaleTimeString('it-IT', {hour: 'numeric', minute: 'numeric'})}`}
            </Typography>
          </Alert>
        </Box>}
      {error &&
        <Box position={'fixed'} top={'50px'} left={0} right={0} zIndex={1}>
          <Alert severity={"error"} sx={{m: 2, boxShadow: '0px 1px 18px 0px rgba(48,66,75,0.45)'}}
                 onClose={hanldleCloseAlert}
          >
            <AlertTitle>QR Code non valido.</AlertTitle>
            <Typography variant={'subtitle2'}>{error.response.data.error}</Typography>
          </Alert>
        </Box>}
      <Box width={'100%'} mt={`${APP_BAR_HEIGHT}px`}>
        <QrReader
          onResult={(result, error) => {
            if (!!result) {
              //console.log("result:",result)
              setData(result?.text);
            } else {
              /** TODO CHECK */
              setData('')
            }

            if (!!error) {
              //console.log(error);
            }
          }}
          videoContainerStyle={{width: '100%'}}
          constraints={constraints}
        />
      </Box>
      {count > 0 && !error &&
        <Box px={4} pb={12} sx={{color: 'white'}}>
          <ListItem disablePadding>
            <ListItemIcon>
              <Info fontSize={'large'} color={'error'}/>
            </ListItemIcon>
            <ListItemText primary={`Informazioni: ${user?.nome} ${user?.cognome}`}/>
          </ListItem>
          <OptionalCheckinUserData userData={user} dividerColor={'white'}/>
        </Box>
      }
      <SearchUserDialog open={open} handleClose={handleClose} users={userList} colorField={colorField}/>
      <ConfirmCheckinDialog open={confirmOpen} handleClose={() => setConfirmOpen(false)}
                            user={user ? user : null}
                            colorField={colorField ? colorField : null}
                            alreadyScanned={alreadyScanned}
      />
    </main>
  )
}

export default CheckIn
