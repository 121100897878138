import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import CustomLoader from "../CustomLoader";
import ListItemText from "@mui/material/ListItemText";
import {Info} from "@mui/icons-material";
import useAxios from "axios-hooks";
import {API_URL} from "../../config";
import {getColorFromCategoria} from "../../services/utils";
import {customTheme} from "../../theme/customTheme";
import {find} from "lodash";

export function OptionalCheckinUserData({userData, dividerColor = 'white'}) {
  const [{data: checkinFields, loading: loadingSettings, error: errorSettings}] = useAxios(
    `${API_URL}checkin-fields`, {method: "GET", useCache: false, manual: false}
  )

  return (
    <>
      {
        <List>
          {
            checkinFields?.map((field, i) => (
              <div key={i}>
                <Divider color={dividerColor}/>
                <ListItem sx={{px:0}}>
                  {/*<ListItemIcon>
                                    {(userData && userData[field.id]) ?
                                        userData ? [field.id] === 'Si' ? <Restaurant/> : <NoMeals/>}
                                </ListItemIcon>*/}
                  <ListItemIcon>
                    {<Chip variant={'accent-outlined'}
                           size={'small'}
                           sx={{marginRight: 1}}
                           label={field.label}
                    />}
                  </ListItemIcon>
                  <ListItemText
                    primary={((userData && userData[field.id]) ?
                      userData[field.id].toString().toUpperCase() : '-')}
                  />
                </ListItem>
              </div>

            ))
          }
          <Divider color={dividerColor}/>
        </List>
      }
      {(loadingSettings) && <CustomLoader/>}
      {(errorSettings) && <Typography>Ops... qualcosa è andato storto</Typography>}
    </>
  );
}

const ConfirmCheckinDialog = ({colorField, open, handleClose, user, alreadyScanned, info = false}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{
        background: (user) ?
          (find(colorField?.values||[], ['value', user[colorField?.id]])?.hex
            || getColorFromCategoria(user?.categoria) || 'primary') : 'primary',
        color: user ? customTheme.palette.getContrastText(find(colorField?.values||[], ['value', user[colorField?.id]])?.hex
          || getColorFromCategoria(user?.categoria) || customTheme.palette.primary.main) : 'white'
      }}>
        <Box display={'flex'} alignItems={'center'}>
          <Info color={'primary'} sx={{mr: 2}}/>
          {info ? "Informazioni"
            : alreadyScanned ? `QR code già scansionato ${!!user?.checkinScanCount ? (user.checkinScanCount+' volte') : '' }`||""
              : "Checkin avvenuto con successo"}
        </Box>
      </DialogTitle>
      <DialogContent sx={{px:1}}>
        <DialogContent id="alert-dialog-description">
          {!user && <CustomLoader/>}
          {user ?
            <Box>
              <Typography gutterBottom variant={"h5"}>{user?.nome} {user?.cognome}</Typography>
              <OptionalCheckinUserData userData={user}/>
              {
                user.checkedInAt ?
                  <Chip label={`Primo check-in alle ${new Date((user.checkedInAt?._seconds * 1000))
                    .toLocaleTimeString('it-IT', {hour: 'numeric', minute: 'numeric'})}`}/>
                  : null
              }
            </Box> : null}
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmCheckinDialog;
