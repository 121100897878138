import {ResponsivePie} from '@nivo/pie'
import {useMemo} from "react";
import {alpha, Typography} from "@mui/material";
import {each, snakeCase} from 'lodash'
import {customTheme} from "../../theme/customTheme";
import {lighten} from "@mui/system/colorManipulator";
import SettingsService from "../../services/settings.service";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
/**
 * {
 *         "id": "rust",
 *         "label": "rust",
 *         "value": 489,
 *         //"color": "hsl(345, 70%, 50%)"
 *  }*/

const CustomPieChart = ({utenti, chosenField}) => {
  const parsedData = useMemo(() => {
    if (!utenti || !chosenField)
      return null
    let res = []
    const accumulator = {}

    switch (chosenField.type) {
      case SettingsService.fieldTypes.CHECK_BOX.type:
        each(utenti, (user) => {
          let userVal = chosenField.getter
            ? user[chosenField.getter][chosenField.id]
            : user[chosenField.id]
          userVal = userVal === undefined ? 'Non specificato' : userVal === false ? 'No' : 'Sì'

          if(userVal !== undefined)
            if(!accumulator[userVal]) {
              accumulator[userVal] = 1
            } else {
              accumulator[userVal]++
            }
        })
        res = Object.entries(accumulator).map(([key, counter], i) => {
          return {
            id: key,
            label: key,
            value: counter
          }
        })
        return res
      case SettingsService.fieldTypes.RADIO_GROUP.type:
      case SettingsService.fieldTypes.SELECT.type:
        each(utenti, (user) => {
          const userVal = chosenField.getter
            ? user[chosenField.getter][chosenField.id]
            : user[chosenField.id]

          if(userVal)
            if(!accumulator[userVal]) {
              accumulator[userVal] = 1
            } else {
              accumulator[userVal]++
            }
        })
        res = Object.entries(accumulator).map(([key, counter], i) => {
          return {
            id: key,
            label: key,
            value: counter
          }
        })
        return res
    }

    return null
  }, [utenti, chosenField])

  return parsedData ?
    <ResponsivePie
      data={parsedData}
      margin={{top: 10, right: 150, bottom: 110, left: 170}}
      innerRadius={0.5}
      padAngle={1.6}
      cornerRadius={5}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      colors={[
        lighten(customTheme.palette.accent.main, 0.5),
        lighten(customTheme.palette.primary.main, 0.5),
        customTheme.palette.accent.secondary,
      ]}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.2
          ]
        ]
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={4}
      arcLinkLabelsColor={{from: 'color'}}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            3
          ]
        ]
      }}
      /*legends={[
          {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemTextColor: '#000'
                      }
                  }
              ]
          }
      ]}*/
    /> : <Typography>Errore nel caricamento dei dati</Typography>
}

export default CustomPieChart