import React from "react";
import _ from "lodash";
import {
  Box,
  Checkbox,
  Chip, darken,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider, MobileDateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {customTheme} from "../theme/customTheme";
import {CustomColorPicker} from "./CustomColorPicker";
import SettingsService from "../services/settings.service";

const CustomFieldMultipleUpdate = ({field, value, setValue, disabled}) => {
  const fieldType = _.find(SettingsService.fieldTypes, ['type', field.type])

  function getOptions() {
    switch (field.id) {
      default:
        return field?.values || []

    }
  }

  function getValue() {
    return value
  }

  function getOtherValue() {
    const val = getValue()
    switch (field.id) {
      case "luogoArrivo":
        return getOptions().includes(val) ? "" : val
      case "luogoPartenzaRitorno":
        return field.values.includes(val) ? "" : val
      default:
        return val

    }
  }

  //console.log("formik:",formik)

  return (
    <Grid mt={2} item xs={12} md={field?.md || fieldType?.md || 6}>
      {(() => {
        switch (fieldType) {
          case SettingsService.fieldTypes.RADIO_GROUP:
            return <FormControl fullWidth disabled={field.precompiled || disabled}>
              <Typography variant={'body2'}>{field.label}</Typography>
              <RadioGroup
                row
                value={getValue()}
                onChange={(event) => {
                  //console.log("RadioGroup > event:",event)
                  setValue(event.target.value)
                }}
                name={field.getter ? `${field.getter}.${field.id}` : field.id}
              >
                {
                  (field.values || getOptions()).map((radio) =>
                    <FormControlLabel key={radio.value} value={radio.value} control={
                      <Radio required/>} label={radio.label}/>
                  )
                }
                {field.other
                  && <FormControlLabel
                    checked={!getOptions().includes(getValue())}
                    control={<Radio/>} label={<TextField
                    name={field.getter ? `${field.getter}.${field.id}` : field.id}
                    value={getOtherValue()}
                    onChange={(event) => {
                      //console.log("RadioGroup > event:",event)
                      setValue(event.target.value)
                    }}
                    variant={"standard"} placeholder={'Altro:'}/>
                  }/>}
              </RadioGroup>
            </FormControl>
          case SettingsService.fieldTypes.CHECK_BOX:
            return <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    id={field.id}
                    name={field.id}
                    disabled={field.precompiled || disabled}
                    checked={getValue()}
                    onChange={(event, checked) => {
                      setValue(checked)
                    }}
                    //onBlur={formik.handleBlur}
                    /*onChange={(event, checked) => {
                        formik.setFieldValue(field.id, checked)
                        formik.setFieldTouched(field.id)
                    }}*/
                  />} label={field.label}/>
              </FormGroup>
            </FormControl>
          case SettingsService.fieldTypes.SELECT:
            return <FormControl variant="standard" fullWidth>
              <FormHelperText>{field.label}</FormHelperText>
              <Select
                disabled={field.precompiled || disabled}
                label={field.label}
                id={field.id}
                name={field.id}
                value={getValue()}
                onChange={(event) => {
                  //console.log("SELECT > event:",event)
                  setValue(event?.target?.value)
                }}
                //onBlur={formik.handleBlur}
                //error={Boolean(formik.errors[field.id])}
                //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                //helperText={field.label}
              >
                {(field?.values || getOptions()).map(v =>
                  <MenuItem value={v} key={v.value}>{v.label}</MenuItem>
                )}
              </Select>
            </FormControl>
          case SettingsService.fieldTypes.COLOR:
            return <>
              {
                getOptions().length > 0 ?
                  <Stack direction={'row'} alignItems={'flex-end'}>
                    <FormControl variant="standard" fullWidth>
                      <FormHelperText>{field.label}</FormHelperText>
                      <Select
                        disabled={field.precompiled || disabled}
                        label={field.label}
                        id={field.getter ? `${field.getter}.${field.id}` : field.id}
                        name={field.getter ? `${field.getter}.${field.id}` : field.id}
                        //value={dati[field.id]}
                        value={getValue()}
                        //onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                        onChange={(event) => {
                          setValue(event?.target?.value)
                        }}
                        //onBlur={formik.handleBlur}
                        //error={Boolean(formik.errors[field.id])}
                        //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                        //helperText={field.label}
                      >
                        {(getOptions()).map(v =>
                          <MenuItem value={v.value} key={v.value}>
                            <Chip label={v.label}
                                  size={'small'}
                                  sx={{
                                    backgroundColor: v.hex || 'inherit',
                                    fontWeight: 'bold',
                                    color: v.hex ?
                                      customTheme.palette.getContrastText(v.hex)
                                      : 'inherit',
                                    border: `2px solid ${darken(v.hex || 'white', 0.2)}`
                                  }}/>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Stack>
                  : <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <CustomColorPicker color={getValue()} handleUpdateColor={
                      (colorHex) => {
                        setValue(colorHex.toUpperCase())
                      }
                    }/>
                    <Box sx={{
                      fontWeight: 'bold',
                      //background: getValue() || getColorFromCategoria(formik.values?.categoria) || '#eeeeee',
                      /*color: (!!getValue() ? customTheme.palette.getContrastText(getValue())
                          : !!formik.values?.categoria ? customTheme.palette.getContrastText(getColorFromCategoria(formik.values?.categoria))
                              : 'inherit')*/
                    }}>
                      <Typography>
                        {`${field?.label}: ${getValue() || 'Non definito'}`}
                      </Typography>
                    </Box>
                  </Stack>
              }
            </>
/*
          case SettingsService.fieldTypes.COLOR:
            return <>
              {
                getOptions().length > 0 ?
                  <Stack direction={'row'} alignItems={'flex-end'}>
                    <FormControl variant="standard" fullWidth>
                      <FormHelperText>{field.label}</FormHelperText>
                      <Select
                        disabled={field.precompiled || disabled}
                        label={field.label}
                        id={field.getter ? `${field.getter}.${field.id}` : field.id}
                        name={field.getter ? `${field.getter}.${field.id}` : field.id}
                        //value={dati[field.id]}
                        value={getValue()}
                        onChange={(event) => {
                          //console.log("SELECT COLOR > event:",event)
                          setValue(event?.target?.value)
                        }}
                        //onBlur={formik.handleBlur}
                        //error={Boolean(formik.errors[field.id])}
                        //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                        //helperText={field.label}
                      >
                        {(getOptions()).map(v =>
                          <MenuItem value={v.value} key={v.value}>
                            <Chip label={v.label}
                                  sx={{
                                    backgroundColor: v.value || 'inherit',
                                    color: v.value ? customTheme.palette.getContrastText(v.value) : 'inherit'
                                  }}/>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Stack>
                  : <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <CustomColorPicker color={getValue()} handleUpdateColor={
                      (colorHex) => setValue(colorHex)
                    }/>
                    <Box sx={{
                      fontWeight:'bold',
                      //background: getValue() || getColorFromCategoria(formik.values?.categoria) || '#eeeeee',
                      /!*color: (!!getValue() ? customTheme.palette.getContrastText(getValue())
                          : !!formik.values?.categoria ? customTheme.palette.getContrastText(getColorFromCategoria(formik.values?.categoria))
                              : 'inherit')*!/
                    }}>
                      <Typography>
                        {`${field?.label}: ${getValue() || 'Non definito'}`}
                      </Typography>
                    </Box>
                  </Stack>
              }
            </>
*/
          case SettingsService.fieldTypes.DATE:
            return <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                renderInput={(params) =>
                  <TextField {...params} id="name-input"
                             variant={'standard'}/>}
                label={field.label}
                onChange={(date, selectionState) => {
                  //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                  //console.log("date:",date)
                  //console.log("selectionState:",selectionState)
                  //formik.setFieldValue(field.id, date)
                  setValue(date)
                }}
                disabled={field.precompiled || disabled}
                value={new Date(getValue()?._seconds ?
                  getValue()._seconds * 1000
                  : getValue()
                ) || ''}
                inputFormat="dd/MM/yyyy"/>
            </LocalizationProvider>
          case SettingsService.fieldTypes.DATE_TIME:
            return <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                renderInput={(params) =>
                  <TextField {...params} id="name-input"
                             variant={'standard'}/>}
                label={field.label}
                onChange={(date, selectionState) => {
                  setValue(date)
                }}
                ampm={false}
                disabled={field.precompiled || disabled}
                value={new Date(getValue()?._seconds ?
                  getValue()._seconds * 1000
                  : getValue()
                ) || ''}
                inputFormat="dd/MM/yyyy, HH:mm"/>
            </LocalizationProvider>
          case SettingsService.fieldTypes.TIME:
            return <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                renderInput={(params) =>
                  <TextField {...params} id="name-input"
                             variant={'standard'}/>}
                label={field.label}
                onChange={(date, selectionState) => {
                  //formik.setFieldValue(field.id, date)
                  //formik.setFieldTouched(field.id)
                }}
                ampm={false}
                disabled={field.precompiled || disabled}
                //value={new Date(formik.values[field.id]) || ''}
                inputFormat="HH:mm"/>
            </LocalizationProvider>
          /*case SettingsService.fieldTypes.URL:
              return formik.values[field.id] ?
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <CustomTooltip
                          title={'Apri link in un\'altra finestra'}
                          children={<Button href={formik.values[field.id]}
                                            target={'_blank'}
                                            sx={{padding: 1.5, backgroundColor: 'transparent'}}
                                            onClick={(event) => event.stopPropagation()}>
                          </Button>}/>
                      <CustomTooltip
                          title={"Copia link"}
                          children={<IconButton color={'primary'}
                                                onClick={() =>
                                                    navigator.clipboard.writeText(field.getter
                                                        ? formik.values[field.getter][field.id]
                                                        : formik.values[field.id])
                          }>
                              <ContentCopy fontSize={'small'}/>
                          </IconButton>}/>
                  </Box> : ''*/
          case SettingsService.fieldTypes.TEXT_BOX:
            return <TextField id={field.id}
                              name={field.id}
                              disabled={field.precompiled || disabled}
                              multiline={true}
                              minRows={2}
                              maxRows={3}
                              value={getValue()}
                              onChange={(event) => {
                                setValue(event.target.value)
                              }}
                              label={field.label}
                              variant={"outlined"}
                              sx={{width: '100%'}}/>
          default:
            return <TextField id={field.id}
                              name={field.id}
                              disabled={field.precompiled || disabled}
                              value={getValue()}
                              onChange={(event) => {
                                setValue(event.target.value)
                              }}
                              label={field.label}
                              variant={"standard"}
                              sx={{width: '100%'}}/>
        }
      })()}
    </Grid>)
}

export default CustomFieldMultipleUpdate