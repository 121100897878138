import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../state/global";
import {filter, find} from "lodash";
import ColumnsService from "../../services/columns.service";
import {Autocomplete, Chip, Paper, Stack, TextField, Typography} from "@mui/material";
import {lighten} from "@mui/system/colorManipulator";
import {customTheme} from "../../theme/customTheme";
import CustomTooltip from "../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";
import {Cancel, Help} from "@mui/icons-material";
import SettingsService, {forms} from "../../services/settings.service";
import {GroupHeader, GroupItems} from "./CheckinFieldsToShow";

const CheckinColorFieldToShow = ({settings, setSettings}) => {
  const [globalState, ] = useContext(GlobalContext)

  const [state, setState] = useState({
    fields: [],
    colorField: null
  })

  useEffect(() => {
    if(!!globalState.dataStructures) {
      let _fields = []
      filter(Object.values(globalState.dataStructures), function (o) {
        return o.id !== forms.CHECKIN.id
      }).forEach((dataStructure) => {
        _fields = _fields.concat(filter(
          ColumnsService.getFieldsFromForm(dataStructure),
          function (o) {
            return o.type === SettingsService.fieldTypes.COLOR.type
          }
        ))
      })
      setState({...state, fields: _fields})
    }
  }, [globalState.dataStructures])

  function handleDeleteColorField() {
    setSettings({
      ...settings,
      colorField: null
    })
  }

  return (
    <Stack py={1} px={2} spacing={3}>
      <Stack p={2} direction={'row'} alignItems={'baseline'} spacing={1} component={Paper}
             sx={{background: lighten(customTheme.palette.primary.main, 0.9)}}
      >
        <CustomTooltip
          title={"Il campo Colore scelto andrà a colorare il pop up mostrato al checkin per poter categorizzare gli utenti"}
          children={<IconButton size={'small'}>
            <Help color={'disabled'}
                  fontSize={'small'}/>
          </IconButton>}
        />
        <Typography variant={'body1'} fontWeight={'bold'}>Campo Colore mostrato al checkin: </Typography>
        {settings.colorField ?
          <Chip
            size={'small'}
            sx={{marginBottom: 0.5, marginRight: 0.5}}
            color={"primary"}
            label={find(state.fields, ['id', settings.colorField.id])?.label}
            deleteIcon={<Cancel/>}
            onDelete={() => handleDeleteColorField()}
          />
          : <Typography>Nessuno</Typography>}
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Stack width={'100%'} direction={'row'} alignItems={'flex-start'}>
          <Autocomplete
            fullWidth
            size={'small'}
            disableCloseOnSelect
            onChange={(event, newValue) => {
              if (newValue)
                setSettings({
                  ...settings,
                  colorField: newValue
                })
            }}
            renderInput={(params) =>
              <TextField {...params}
                         label="Scegli il campo Colore da mostrare al checkin:"
              />}
            options={state.fields}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CheckinColorFieldToShow
