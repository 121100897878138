import React, {useContext, useState} from "react";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Snackbar,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {roles} from "../../config";
import useAxios from "axios-hooks";
import AuthService from "../../services/auth.service";
import CustomLoader from "../../components/CustomLoader";
import CustomDialogTitle from "../../components/CustomDialogTitle";
import IconButton from "@mui/material/IconButton";
import {Add, DeleteForever, PersonAdd} from "@mui/icons-material";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import {GlobalContext} from "../../state/global";
import {some} from "lodash";
import {Alert} from "@mui/lab";
import CustomConfirmDialog from "../../components/CustomFileUploader";

const WinstonMembersSettings = () => {
    const [globalState,] = useContext(GlobalContext)
    const [{data: membersData, loading: loadingMembers, error: errorMembers}, refetch] = useAxios(
        AuthService.authUrl(), {method: "GET", useCache: false, manual: false}
    )

    const [error, setError] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [memberData, setMemberData] = useState({
        email: '',
        password: '',
        displayName: '',
        role: roles.AGENCY.id
    })

    const handleToggleDialog = () => {
        setMemberData({
            email: '',
            displayName: '',
            password: '',
            role: roles.AGENCY.id
        })
        setError(null)
        setOpenDialog(!openDialog)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldId = event.target.id || event.target.name
        if (fieldId === 'email') {
            setIsValidEmail(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/.test(event.target.value))
        }

        setMemberData({...memberData, [fieldId]: event.target.value});
    };

    const handleAddMember = () => {
        axios({
            url: `${AuthService.authUrl()}`,
            method: 'POST',
            data: {...memberData, email: memberData.email.trim().toLowerCase(), role: memberData.role},
        })
            .then((res) => {
                if (res) {
                    refetch().then(() => {
                        handleToggleDialog()
                    })
                }
            })
            .catch((err) => {
                setError(err.response?.data?.error || "Inserimento non andato a buon fine")
                console.log("handleSubmit new user --> err:", err)
            })
    }

    function handleCloseError() {
        setError(null)
    }

    function handleDisableMemeber(uid, enabled) {
        axios({
            url: `${AuthService.authMemberUrl(uid)}/disable`,
            method: 'PATCH',
            data: { disabled: !enabled },
        })
            .then((res) => {
                if (res) {
                    refetch().then(() => {

                    })
                }
            })
            .catch((err) => {
                setError(err.response?.data?.error || "Modifica non andata a buon fine")
                console.log("handleDisable user --> err:", err)
            })
    }

    async function handleDeleteMember(uid) {
        axios({
            url: `${AuthService.authMemberUrl(uid)}`,
            method: 'DELETE',
        })
            .then((res) => {
                if (res) {
                    refetch().then(() => {

                    })
                }
            })
            .catch((err) => {
                setError(err.response?.data?.error || "Modifica non andata a buon fine")
                console.log("handleDelete user --> err:", err)
            })
    }

    return (
        <Stack>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error"
                       sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
            <Box display={'flex'} alignItems={'center'}>
                <Typography variant={'h6'}>Utenti di Back Office</Typography>
                {globalState?.authState?.isAdmin &&
                    <IconButton
                        onClick={handleToggleDialog}
                        children={<Add/>}
                    />}
            </Box>
            {loadingMembers && <CustomLoader/>}
            <List>
                {
                    membersData?.members.map((member, index) => (
                        <ListItem key={index} dense>
                            <ListItemAvatar children={
                                <Avatar children={roles[member.role]?.icon}/>
                            }/>
                            <ListItemText
                                primary={`${member.email} ${member.displayName ? (" - " + member.displayName) : ''}`}
                                secondary={`Ruolo: ${roles[member.role]?.label || member.role}`}
                            />
                            {globalState?.authState?.isAdmin &&
                                <ListItemSecondaryAction
                                    children={<Stack spacing={2} direction={'row'} alignItems={'center'}>
                                        <Switch
                                            edge="end"
                                            size={'small'}
                                            onChange={(event, checked) => handleDisableMemeber(member.uid, checked)}
                                            checked={!member.disabled}
                                            disabled={!globalState?.authState?.isAdmin}
                                        />
                                        <CustomConfirmDialog label={'Elimina'}
                                                             icon={<DeleteForever/>}
                                                             size={'small'}
                                                             disabled={!globalState?.authState?.isAdmin}
                                                             confirmAction={() => handleDeleteMember(member.uid)}
                                        />
                                    </Stack>}
                                />}
                        </ListItem>
                    ))
                }
            </List>
            <Dialog open={openDialog}>
                <CustomDialogTitle title={'Aggiungi nuovo membro'} handleCloseDialog={handleToggleDialog}/>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="displayName"
                        label="Nome"
                        type="text"
                        fullWidth
                        variant="standard"
                        required
                        value={memberData.nome}
                        onChange={handleChange}
                    />
                    <TextField
                        select
                        autoFocus
                        margin="dense"
                        id="role"
                        name="role"
                        label="Ruolo"
                        type="text"
                        fullWidth
                        variant="standard"
                        required
                        value={memberData.role}
                        onChange={handleChange}
                    >
                        {Object.entries(roles).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="E-mail"
                        type="email"
                        fullWidth
                        variant="standard"
                        required
                        value={memberData.email}
                        onChange={handleChange}
                        error={!isValidEmail}
                        helperText={"L'e-mail deve essere valida"}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Password"
                        type="text"
                        fullWidth
                        variant="standard"
                        required
                        value={memberData.password}
                        onChange={handleChange}
                        error={memberData.password.length < 6}
                        helperText={"Minimo 6 caratteri"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleDialog}>Annulla</Button>
                    <Button variant={'submit'} startIcon={<PersonAdd/>}
                            disabled={!isValidEmail || (some(Object.values(memberData), function (o) {
                                return !o
                            }))}
                            onClick={handleAddMember}>
                        Aggiungi
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default WinstonMembersSettings