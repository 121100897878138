import React from "react";
import _, {filter, find, includes, map, split} from 'lodash'
import {parseMultiLineTextBox} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import {Box, Button, Chip, darken, Stack, Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../components/CustomTooltip";
import {BlurOn, Check, Close, ContentCopy, FiberManualRecord, FileOpen, Link, OpenInNew} from "@mui/icons-material";
import ImgPopUpComponent from "../components/ImgPopUpComponent";
import {customTheme} from "../theme/customTheme";
import SettingsService from "./settings.service";

const columnsUrl = (confId) => {
  return `${API_URL}config/columns/${confId || ''}`
}

const saveColumsToExport = (confId, columns) => {
  return axios.post(`${columnsUrl()}export`, {[confId]: columns},)
};

const saveDefaultVisibilityModel = (formId, columns) => {
  return axios.post(`${columnsUrl()}visibility`, {[formId]: {defaultVisibilityModel: columns}},)
};

const getFieldsFromForm = (form) => {
  let fields = []
  if (form) {
    if (form.sections) {
      form.sections.forEach((section) => {
        fields = fields.concat(map(section.fields, (field) => ({...field, section: section.label})))
      })
      return fields
    } else {
      return form.fields
    }
  }
}

const getColumns = (form, refetch) => {
  const cols = filter(getFieldsFromForm(form), function (o) {
    const field = find(SettingsService.fieldTypes, ['type', o.type])
    return !field?.onlyUserForm && !field?.noDataGrid
  })?.map((field) => {
    function getValue(field, params) {
      const id = (includes(field.id, '.')) ? split(field.id, '.')[1] : field.id
      return field?.getter ? params.row[field.getter]?.[id] : params.row[id]
    }

    let baseField = {
      field: field.id || field,
      headerName: field.headerName || field.label || field.id || (_.startCase(field)),
      flex: 1,
      minWidth: 100,
      type: (field.type === 'select' || field.type === 'radiogroup')
        ? 'singleSelect' : field.type,
      values: field.values,
      valueOptions: (field.type === 'select' || field.type === 'radiogroup')
        ? field.values?.map((opt) => (field.type === 'select' ? opt.value : opt.label)) : null
    }

    baseField = field?.getter ? {
      ...baseField,
      valueGetter: (params) => {
        return getValue(field, params)?._seconds ?
          new Date(getValue(field, params)?._seconds * 1000)
          : getValue(field, params)
      }
    } : {
      ...baseField, valueGetter: (params) => {
        return getValue(field, params)?._seconds ?
          new Date(getValue(field, params)?._seconds * 1000)
          : getValue(field, params)
      }
    }

    const formType = _.find(SettingsService.fieldTypes, ['type', field.type])
    if (!formType?.onlyUserForm) {
      switch (formType) {
        case SettingsService.fieldTypes.DATE:
          return {
            ...baseField,
            type: 'date',
            valueFormatter: (params) => {
              return (params.value?._seconds ?
                new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                : params.value ?
                  new Date(params.value).toLocaleDateString('it-IT')
                  : "");
            },
          }

        case SettingsService.fieldTypes.DATE_TIME:
          return {
            ...baseField,
            type: 'date',
            valueFormatter: (params) => {
              return (params.value?._seconds ?
                new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                : params.value ?
                  new Date(params.value).toLocaleString('it-IT')
                  : "");
            },
          }

        case SettingsService.fieldTypes.TIME:
          return {
            ...baseField,
            type: 'dateTime', // TODO: capire
            valueFormatter: (params) => {
              return (params.value?._seconds ?
                new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                : params.value ?
                  new Date(params.value).toString() !== 'Invalid Date' ?
                    new Date(params.value).toLocaleTimeString('it-IT')
                    : params.value
                  : "");
            },
          }

        case SettingsService.fieldTypes.CHECK_BOX:
          return {
            ...baseField,
            flex: 0.6,
            valueGetter: (params) => {
              if (getValue(field, params) === true || getValue(field, params) === 'true' || getValue(field, params) === 'si' || getValue(field, params) === 'sì') {
                return 'si';
              } else if (getValue(field, params) === false || getValue(field, params) === 'false' || !getValue(field, params) || getValue(field, params) === 'no')
                return 'no';
              else return getValue(field, params) || '-'
            },
            valueFormatter: (params) => {
              /** e.g. */ //const partecipazione = params.api.getRow(params.id)?.partecipazione
              if (params.value === true || params.value === 'true' || params.value === 'si' || params.value === 'sì') {
                return 'Si';
              } else if (params.value === false || params.value === 'false' || params.value === 'no' || !params.value)
                return 'No';
              else return 'Non specificato'
            },
            renderCell: (params) => (getValue(field, params) === true || getValue(field, params) === 'true' || getValue(field, params) === 'si' || getValue(field, params) === 'sì')
              ? <Check sx={{color: 'green'}}/>
              : (getValue(field, params) === false || getValue(field, params) === 'false' || getValue(field, params) === 'no' || getValue(field, params) === '')
                ? <Close color={'disabled'}/>
                : '-'
          }

        case SettingsService.fieldTypes.URL:
          return {
            ...baseField,
            width: 25,
            flex: 0,
            renderCell: (params) => params.value ?
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                {/*<CustomTooltip title={"Copia link"}
                                    children={<IconButton size={'small'} color={'primary'}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    navigator.clipboard.writeText(params.value)
                                                    //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))

                                                }}>
                                        <ContentCopy fontSize={'small'}/>
                                    </IconButton>}
                                />*/}
                <CustomTooltip
                  title={'Apri link in un\'altra finestra'}
                  children={
                    <IconButton size={'small'} color={'primary'}
                                href={params.value}
                                target={'_blank'}
                                onClick={(event) => event.stopPropagation()}>
                      <OpenInNew fontSize={'small'}/>
                    </IconButton>}/>
              </Box> : ""
          }

        case SettingsService.fieldTypes.ACCESS_TYPE:
          return {
            ...baseField,
            disableExport: true,
            renderCell: (params) => {
              if (params.row.partecipazione === 'online')
                return <CustomTooltip title={"Copia link streaming"} children={
                  <Button
                    color={'accent'}
                    onClick={async (event) => {
                      event.stopPropagation()
                      await navigator.clipboard.writeText(`https://meetwinston.app/live/${params.id}`)
                    }}>
                    <Link/>
                  </Button>
                }/>
              else if (params.row.partecipazione === 'si')
                return !!params.row.qrCodeUrl ?
                  <ImgPopUpComponent filename={`QR-${params.row.nome || ''}-${params.row.cognome || ''}-${params.id}`}
                                     imgUrl={params.row.qrCodeUrl}/>
                  : <Chip label={'No QR Code'} size={'small'}/>
              else if (params.row.partecipazione === 'no')
                return ''
            }
          }

        case SettingsService.fieldTypes.RADIO_GROUP:
          return {
            ...baseField,
            //flex: 0.6,
            valueGetter: (params) => {
              const label = find(field.values, ['value', params.value])?.label
              return label && label !== "Nessuna delle precedenti" ? label : params.value
            },
            valueFormatter: (params) => {
              if (params.value === true || params.value === 'si' || params.value === 'sì' || params.value === 'partecipo') {
                return 'Si';
              } else if (params.value === false || params.value === 'no' || params.value === 'nonPartecipo')
                return 'No';
              else {
                const label = find(field.values, ['value', params.value])?.label
                return label && label !== "Nessuna delle precedenti" ? label : params.value
              }
            },
            renderCell: (params) => {
              return !!params?.value ? (params?.value === true
                || params?.value?.toLowerCase() === 'si' || params?.value?.toLowerCase() === 'sì'
                || params?.value === 'partecipo')
                ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                : (params?.value === false || params?.value?.toLowerCase() === 'no' || params?.value === 'nonPartecipo')
                  ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                  : (find(field.values, ['value', params?.value])?.label || params?.value || '-') : null
            }
          }

        case SettingsService.fieldTypes.SELECT:
          return {
            ...baseField,
            valueFormatter: (params) => {
              const label = find(field.values, ['value', params.value])?.label
              return label && label !== "Nessuna" ? label : params.value
            },
          }

        case SettingsService.fieldTypes.TEXT_BOX:
          return {
            ...baseField,
            valueFormatter: (params) => {
              return parseMultiLineTextBox(params.value)
            },
          }

        case SettingsService.fieldTypes.COLOR:
          return {
            ...baseField,
            /*valueGetter: (params) => {
                return getLabelFromColor(params.value)
            },*/
            valueFormatter: (params) => {
              return field.values?.length > 0 ? (find(field.values, ['value', params?.value])?.label || params?.value || '-')
                : params?.value
            },
            renderCell: (params) => {
              const colorData = find(field.values, ['value', params?.value])
              let color = field.values?.length > 0 ? (colorData?.hex || params?.value || '-')
                : params?.value

              return (color && /^#[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(color)) ?
                <Stack>
                  <Chip sx={{
                    backgroundColor: color,
                    fontWeight: 'bold',
                    color: customTheme.palette.getContrastText(color),
                    border: `2px solid ${darken(color, 0.2)}`
                  }}
                        size={'small'}
                        label={colorData?.label || params?.value}
                  />
                </Stack>
                : (color || '-')//<DoDisturb color={'disabled'}/>
            }
          }

        case SettingsService.fieldTypes.FILE_UPLOAD:
          return {
            ...baseField,
            renderCell: (params) => {
              return params?.value ?
                <CustomTooltip title={`Apri ${field.label}`}
                               children={
                                 <IconButton size={'small'}
                                             color={"accent"}
                                             variant={'outlined'}
                                             href={params?.value}
                                             target={'_blank'}
                                             onClick={(event) => event.stopPropagation()}
                                 >
                                   <FileOpen/>
                                 </IconButton>}
                /> : '-'
            }
          }

        case SettingsService.fieldTypes.CUSTOM:
          return {
            ...baseField,
            renderCell: (params) => {
              const max_imgs = 3
              const picsObjs = params?.value || []
              const leftImgs = picsObjs?.length - max_imgs

              return <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'}>
                {picsObjs?.slice(0, max_imgs).map((pic, i) => (
                  <ImgPopUpComponent key={i} imgUrl={pic.url} maxWidthImg={'100%'}/>))}
                {leftImgs > 0 &&
                  <Chip label={`+${leftImgs}`} size={'small'} variant={'accent-outlined'}/>}
              </Stack>
            }
          }
        default:
          return baseField
      }
    }
  })

  return cols;
};

const ColumnsService = {
  columnsUrl,
  getColumns,
  saveColumsToExport,
  saveDefaultVisibilityModel,
  getFieldsFromForm
};

export default ColumnsService;